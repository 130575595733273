/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import 'themes/default.scss';

/* Import Codemirror Code Editor styles */
@import '~codemirror/lib/codemirror';

@font-face {
  font-family: 'Roboto';
  src: url('assets/font-roboto/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('assets/font-roboto/Roboto-Light.ttf');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('assets/font-roboto/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('assets/font-roboto/Roboto-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

/* Codemirror */
.CodeMirror {
  height: 305px;
}
.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  font-size: .8rem;
}

/* Angular Split */
.as-split-gutter {
  background-color: #f8f9fa !important;
}

/* Angular Editor */
.angular-editor-toolbar, .angular-editor .angular-editor-wrapper .angular-editor-textarea {
  background-color: #fff !important;
}
.angular-editor-textarea {
  margin-top: 0 !important;
}

/* Angular Bootstrap */
ngb-datepicker-navigation-select>.custom-select {
  font-size: .8rem !important;
}
.ngb-toasts {
  position: fixed;
  top: .5rem;
  z-index: 1000;
  right: 1rem;
}
.toast.bg-warning.text-light {
  color: #000 !important;
}


.ng-dropdown-panel {
  left: -.125rem;
  padding: .25rem;
  background-color: #fff;
}

/* Basic layout */
a, .btn-link {
  color: #1183b0;
  font-weight: 500;
  text-decoration: none;
}
a:hover, .btn-link:hover {
  text-decoration: none;
  color: #15a6df;
}
body {
  height: 100%;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: .8rem;
  overflow-y: hidden;
  overflow-x: hidden;
}

fieldset {
  margin-bottom: .75rem;
  padding: .25rem 1rem;
  border: 1px solid #dee2e6;
}
fieldset fieldset:last-child {
  margin-bottom: .25rem;
}
fieldset.sub-section {
  margin-left: 1.5rem;
}

h4 {
  font-weight: 400;
}
h5 {
  font-size: 1rem;
  font-weight: 400;
}
h5 .fa, h5 .fas, h5 .far {
  font-size: 85%;
}
h5.subtitle {
  color: #00628f;
  font-weight: 500;
}
h6 {
  font-size: 0.82rem;
}

html {
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.iframe-container {
  background-color: #3c4953;
}

legend {
  float: none;
  width: auto;
  line-height: 1;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
}

ol, ul, dl {
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

.opacity-7 {
  opacity: .7;
}

.fw-500 {
  font-weight: 500;
}

/* Colors */

.bg-white {
  background-color: #fff;
}
.bg-blue-extra-light {
  background-color: #ddecff8c;
}

/* Bootstrap components */

.accordion-button {
  font-size: .85rem;
}

.badge.badge-xl {
  font-size: 100%;
  font-weight: 400;
}

.btn.btn-block {
  width: 100%;
}
.btn.btn-xs {
  padding: 0 .25rem;
}

.bg-light-darker {
  background-color: #c6cad6a6 !important;
}
.bg-primary {
  background-color: #1183b0 !important;
}
.bg-info {
  background-color: #88d5f4 !important;
}
.toolbar label, .btn-group label {
  margin-right: .5rem;
  margin-top: 0.45rem;
}
.btn-primary {
  background-color: #15a6df !important;
  border-color: #15a6df;
}
.btn-outline-primary {
  border-color: #15a6df;
}
.btn-outline-secondary {
  background-color: #fff;
  border-bottom-width: 1px !important;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.show > .btn-outline-primary.dropdown-toggle
{
  color: #fff !important;
  background-color: #1183b0;
  border-color: #1183b0;
}
.btn-outline-primary:not(:disabled) {
  color: #1183b0;
}
.btn-outline-primary:hover {
  color: #fff;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  background-color: #f3f3f3;
  border: 1px solid #15a6df;
}
.btn-light, .btn-light.dropdown-toggle:after {
  color: #666;
}
.btn.disabled, .btn:disabled {
  opacity: 0.25;
}
.btn-group .JBMButton:not(:last-child) {
  margin-right: 0;
}

.card-header {
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-bottom: 1px solid #15a6df;
}

.card-header .btn-link {
  padding-left: 0;
  outline: none;
  box-shadow: none;
  text-decoration: none;
}

.card-header .action-buttons {
  position: absolute;
  right: .5rem;
  top: .21rem;
}

.card-header .action-buttons div.dropdown, .card-header .action-buttons div.dropup,

td.action-buttons .dropdown, td.action-buttons .dropup {
  display: inline-block;
}

.card-body {
  padding: 1rem;
}

.dropdown-menu.table-cell {
  width: 15rem;
  padding:1rem;
}
.dropdown-menu {
  font-size: inherit;
  border-radius: 0;
}
.dropdown-menu.confirm-delete {
  margin-top: -8.75rem;
  margin-right: -3.25rem;
}
.dropdown-menu.table-cell.comments {
  background-color: #ffffd8;
  max-height: 252px;
  overflow-y: auto;
}

.dropdown-toggle.actions:after {
  display: none;  /* Hide arrow */
}

.dropdown-item {
  padding-left: 1.5rem;
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: #ebecf0;
}
.dropdown-item.active, .dropdown-item:active {
  color: inherit;
}
.dropdown-item i.fa, .dropdown-item i.fas {
  color: #8197a6;
  width: .9rem;
  margin-left: -1.15rem;
}

.col-form-label {
  text-align: right;
  color: #000;
}

.col-form-label-sm {
  font-size: inherit;
}

.form-control {
  height: auto;
  background-color: #f9fafa;
  border-width: 1px;
  color:#000;
}
.input-group-text {
  font-size: 85%;
}

.modal-content {
  border-radius: 0;
}

.modal-header, .modal-body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.nav-link {
  color: #1183b0;
}
.nav-link:hover {
  color: #15a6df;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
  color: #495057;
  border-bottom: 3px solid #128fbf;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs a.nav-link.nav-item:not(.active) {
  font-weight: 400;
}

.nav-tabs .nav-link {
  padding: .4rem 0 0.3rem;
  margin-right: 1.5rem;
  border: none;
}

.nav-tabs .nav-link:last-child {
  margin-right: 0;
}
ol.breadcrumb {
  margin-bottom: 0;
  background-color: #f8f9fa;
}
ol.breadcrumb.padding {
  padding-left: 1.5rem;
}

/* Pagination  */

.page-link {
  font-size: inherit;
}
.pagination .page-item.active .page-link {
  background-color: #1183b0;
  border-color: #1183b0;
  font-weight: 700;
  color: #fff;
}
.pagination .page-link {
  background-color: #f8f9fa;
  border: none;
}
.page-item:first-child .page-link {
  border-radius: 0;
}
.popover {
  font-size: inherit;
}
.popover-header {
  font-size: .85rem;
}
.row {
  flex-wrap: nowrap;
/*  --bs-gutter-x: 0;*/
}

.tooltip-inner {
  background-color: #343a40;
  color: #fff;
  font-size: .75rem;
}

.tooltip.show {
  opacity: 1 !important;
  filter: alpha(opacity=100);
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #343a40 !important;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #343a40 !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #343a40 !important;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #343a40 !important;
}

span.hover-tooltip {
  color: #999;
  border-bottom: 1px dotted #999;
}

/* Angular Calendar */

/* Month view */

.cal-month-view .cal-event {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 0;
}
.cal-month-view .cal-events {
  margin-left: .9rem;
  margin-top: .4rem;
}
.cal-month-view .cal-cell.cal-has-events .badge {
  margin-top: 6px;
  margin-left: 1rem;
}

.cal-month-view .cal-day-cell:focus {
  outline: none;
  border: 1px solid #15a6df;
}
.cal-month-view .cal-day-cell .cal-tooltip .badge {
  margin-left: 0;
}
.cal-tooltip {
  opacity: 1;
}
.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  color: #343a40;
}
.cal-month-view .cal-day-cell.cal-today {
  background-color: #e4f6fc;
}
.cal-month-view .cal-cell-row .cal-day-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #f4fbfe;
}
.cal-month-view .cal-day-number {
  font-size: 100%;
  font-weight: 600;
  opacity: 0.4;
  margin-top: .2rem;
  margin-right: .5rem;
  float: right;
}
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.55em;
  margin-top: 0;
}
.cal-month-view .cal-open-day-events {
  margin-top: -2px;
  max-height: 11.7rem;
  overflow-y: auto;
  color: inherit;
  background-color: #f4fbfe;
  box-shadow: none;
  border: 1px solid #15a6df;
}
.cal-month-view .cal-cell.cal-has-events.cal-open,
.cal-month-view .cal-cell.cal-has-events.cal-open:focus {
  outline: none;
  border: 1px solid #15a6df;
  border-bottom: none;
  z-index:1000;
}


div.app {
  height: 100%;
}

div.guest {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Sidebar */
.sidebar {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 11rem;
  background-color: #0074AD;
  background-image: linear-gradient(to right, #006394 5%, #0085c7 95%);
  overflow: hidden;
}
body.sidebar-narrow .sidebar {
  width: 4rem;
}
body:not(.sidebar-narrow) .sidebar-tooltip {
  display: none;
}
.sidebar .menuitem-container {
  height: 2.35rem;
}
.sidebar .menuitem-container a.new-tab {
  display: none;
}
.sidebar .menuitem-container:hover a.new-tab {
  display: block;
}
.sidebar a span {
  display: block;
  float:left;
  margin-left: .5rem;
}
.sidebar a i, body.sidebar-narrow.sidebar-expand .sidebar a i {
  float:left;
}
body.sidebar-narrow:not(.sidebar-expand) .sidebar a.link {
  width: auto;
  padding-right: 0.75rem;
  padding-bottom: 0.424rem;
}
body.sidebar-narrow:not(.sidebar-expand) .sidebar a.new-tab {
  display: none;
}
body.sidebar-narrow .sidebar a span {
  display: none;
}
body.sidebar-narrow .sidebar a i {
  float: none;
}
.sidebar .toggle-container {
  height: 2.2rem;
}
.sidebar .toggler {
  width: 2rem;
  padding: .52rem;
  cursor: pointer;
}
.sidebar .toggler:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f359"; /* Arrow left */
  color: #eee;
}
body.sidebar-narrow .sidebar .toggler:after {
  content: "\f35a"; /* Toggle right */
}
.sidebar .toggler:hover:after {
  color: #fff;
}
.sidebar .fa, .sidebar .fas, .sidebar .far {
  width: 1.4rem;
  color: #cdcdcd;
  font-size: 1.2rem;
}
body.sidebar-narrow.sidebar-expand .sidebar {
  width: 11rem;
  border-right: 1px solid #bbb;
  z-index: 1000;
}
body.sidebar-narrow.sidebar-expand .sidebar a span {
  display: block;
}
body.sidebar-narrow.sidebar-expand .sidebar-tooltip {
  display: none;
}
.sidebar .company {
  position: relative;
  min-height: 4rem;
  padding-top: .5rem;
  text-align: center;
  background-color: #fff;
}
.sidebar .company img {
  width: 50px;
  height: 40px;
  margin-top: .25rem;
}
.sidebar .user .btn {
  color: #777;
  font-weight: 500;
}
.sidebar .user .btn:focus {
  box-shadow: none;
}
.sidebar .user .dropdown-toggle {
  width: 100%;
}
.sidebar .user .dropdown-menu {
  min-width: 9rem;
  width: 9rem;
}
.sidebar .menuitems-container {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 3rem;

}
.sidebar .menuitems {
  margin-left: .5rem;
  margin-right: .5rem;
}

.sidebar .menuitem-container a.link {
  width: 7.5rem;
  float: left;
}
.sidebar .user .menuitem-container a.link {
  width: 10rem;
}
.sidebar .menuitem-container a.link:hover, .sidebar .menuitem-container a.new-tab:hover {
  background-color: rgb(235, 236, 240);
  color: #343434;
  border-radius: 0.375rem;
}
.sidebar .menuitem-container a.new-tab {
  width: 2rem;
  float: left;
  margin: 0;
  padding: .825rem 1.2rem;
  padding-left: 0.26rem;
}
.sidebar .menuitem-container a.new-tab .fa {
  width: 1.5rem;
  color: #999;
}
.sidebar .menuitem-container a.new-tab:hover {
  color: #343434;
}
.sidebar .menuitem-container a.new-tab .fa {
  font-size: .75rem;
}
.sidebar .menuitems a {
  position: relative;
  display: block;
  padding: .6rem .5rem;
  text-align: center;
  font-weight: 400;
  color: #fff;
}
.sidebar .menuitems a:hover:not(.active), div.sidebar-menu a:hover:not(.active) {
  color: #343434;
}
.sidebar .menuitems a.active, div.sidebar-menu a.active {
  border-radius: 0.375rem;
  background-color: rgb(235, 236, 240);
  color: #1183b0;
  font-weight: 500;
}
.sidebar .menuitems a.active .far, .sidebar .menuitems a.active .fas, .sidebar .menuitems a:hover:not(.active) .fas {
  color: #666;
}
div.sidebar-menu {
  position: absolute;
  top: 3.5rem;
  left: 6rem;
  bottom: 0;
  width: 12rem;
  color: #555;
  z-index: 1;
}
div.sidebar-menu .header {
  padding: .85rem 1.5rem .95rem 1.5rem;
  font-size: 1.1rem;
}
div.sidebar-menu .header .btn {
  float: right;
  margin-top: -.2rem;
  margin-right: -.75rem;
  color: #999;
}
div.sidebar-menu .menuitems {
  padding: 0 1rem;
  font-size: 0.825rem;
}
body:not(.sidebar-menu) .sidebar-menu {
  display: none;
}
div.sidebar-menu a {
  display: block;
  padding: .5rem;
  color: #555;
}
div.sidebar-menu .fas {
  padding-right: .5rem;
}
/* Content section */

.content {
  position: absolute;
  top: 0;
  left: 11rem;
  right: 0;
  bottom: 0;
}

body.sidebar-narrow .content {
  left: 4rem;
}

.app-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
  display: none;
}

body.show-backdrop .app-backdrop {
  display: block;
}

body.show-backdrop.backdrop-light .app-backdrop {
  opacity: .1;
}

/* Ensure slide panels collapses when no backdrop active */

body:not(.aside-right) aside.right {
  width: 0;
}

body:not(.aside-left) aside.left {
  width: 0;
}

/* Aside sliding panels */

aside {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 2000;
  overflow-y: auto;
}

aside.right {
  right: 0;
  border-left: 1px solid #ccc;
}

aside.left {
  left: 0;
  border-right: 1px solid #ccc;
}
aside .aside-container {
  height: 100%;
}
aside .aside-header {
  height: 3.52rem;
  padding: 1.2rem 1.5rem;
  padding-top: .7rem;
  border-bottom: 1px solid #eee;
}
aside .aside-title .title {
  float: left;
  margin-bottom: 0;
  line-height: 2;
  padding-right: 1rem;
  font-size: 1.05rem;
  font-weight: 400;
}
aside .aside-subject-title {
  padding: .45rem 1.5rem;
  font-weight: 500;
}

aside .aside-body {
  padding: 1.5rem;
}

/* Functional asides */

aside .jbm-help {
  min-width: 25rem;
  max-width: 25rem;
}

/* GPS Location map */
aside.right .aside-body.gps-location {
  min-width: 25rem;
}
aside.right .aside-body.gps-location .address {
  position: absolute;
  top: 4rem;
}
aside.right .aside-body.gps-location .map {
  position: absolute;
  top: 5.55rem;
  left: 0;
  right: 0;
  bottom: 0;
}

aside.right .ol-overlay-container #ol-marker {
  margin-top: -2rem;
  margin-right: -1rem;
  width: 2rem;
  height: 2rem;
  opacity: .7;
}
aside.right .ol-overlay-container #ol-marker img {
  width: 2rem;
  height: 2rem;
}

body .modal-backdrop.show {
  opacity: 0.20;
}

.app .modal {
  position: absolute;
  top: 0;
  right: 0;
  left: 9rem;
  width: auto;
}

.app .modal-dialog {
  max-width: none;
  margin: 0;
}

.app .modal-content {
  border-width: 0;
}

.app .modal-header {
  line-height: 1.35;
}

.app .modal-header, .app .modal-body, .app .modal-footer {
  padding: 1.4rem 2rem 0.5rem;
}

.app .modal-body {
  padding-bottom: 1.4rem;
}

.app .modal-content {
  border-radius: 0;
}

.action-buttons button, .action-buttons .JBMButton {
  margin-right: .5rem;
}

.action-buttons button:last-child, .action-buttons .JBMButton:last-child,
.action-buttons .dropup:last-child button, .action-buttons .dropdown:last-child button {
  margin-right: 0;
}

.view-section-title {
  padding: .25rem 0;
  border-bottom: 2px solid #15a6df;
  color: #111;
}

.view-section-title.diagonal.blue {
  background: -webkit-linear-gradient(-80deg, #f8f9fa 45%, #d3f1ff 0%);
}
.view-section-title.diagonal.blue.left {
  background: -webkit-linear-gradient(-100deg, #dbe1e5 50%, #b1e6ff 0%);
}

.appDropdown {
  position: absolute;
  top: .9rem;
  right: 1rem;
}
.appDropdown img {
  height: 32px;
}
.appDropdown .dropdown-menu {
  min-width: auto;
}
.appDropdown .dropdown-item {
  padding-left: 1rem;
  text-align: center;
}


/* (Data)Tables */

.table-container {
  width: 100%;
}

.table-container:not(.jbm-table) {
  height: 100%;
}

.table {
  margin-bottom: 0;
}
// Hacks for unwanted bootstrap 5 element styling overrules!
.table > :not(caption) > * > * {
  padding: .25rem .5rem;
  background: none;
}
.table > :not(caption) > * > .btn-danger {
  background-color: #dc3545;
}

.table tr.borderless td {
  border: none;
}

.table th {
  font-weight: 500;
}
.table-container.table-absolute {
  position: absolute;
  top: .12rem;
  left: 0;
  right: 0;
  bottom: 0;
}
.table.jbm-table {
  background-color: #fff;
}
.table.jbm-table th, .table.jbm-table td {
  padding: 0 .25rem;
}
.table.jbm-table td, .table.jbm-table th {
  height: 40px;
}
.table.jbm-table.table-sm td, .table.jbm-table.table-sm th {
  height: 36px;
}
.table.jbm-table.table-xs td, .table.jbm-table.table-xs th {
  height: 32px;
}

.table thead th, .table td {
  vertical-align: middle;
}

.table tr.has-error td {
  vertical-align: top;
  height: 3rem;
}

.table td {
  border-top: 1px solid #f5f7f9;
}
.table th {
  border-top: none;
}
.table th.text-sm, .table td.text-sm {
  font-size: 80%;
}
.table th.weight-700, .table td.weight-700 {
  font-weight: 700;
}
.table td.value {
  font-weight: 600;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f4fbfe;
}
.table.table-row-selectable td {
  cursor: pointer;
}
.table thead th {
  border-bottom: 2px solid #15a6df;
}
.table thead tr.borderless th {
  border-bottom: none;
}
.table-xs th, .table-xs td {
  padding: 0.24rem;
}
.table-xs thead th {
  height: auto;
}
.table-borderless th, .table-borderless td {
  border-top: none;
}
.table-hover tbody tr:hover {
  background-color: #f4f5f7;
}
/* Bootstrap 5 hack */
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: #f4f5f7
}

.table-hover tbody tr.edit-active:hover {
  background-color: #fff;
}

table th.sortable {
  cursor: n-resize;
  color: #1183b0;
}
table th.sortable.sorted:after, table td.checked:after {
  display: inline-block;
  margin-left: .1rem;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  font-weight: 900;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  padding-left: 0;
}
table th.sortable.sorted.desc:after {
  content: "\f30c"; /* Descending arrow */
}
table th.sortable.sorted.asc:after {
  content: "\f309"; /* Ascending arrow */
}
table tr.table-filters td, .table-hover tbody tr.table-filters:hover {
  background-color: #fff;
  cursor: default !important;
}
table td.table-row-expand-btn {
  width: 1.5rem;
}
table tr.table-row-expanded td.expanded {
  padding: 0 !important;
  cursor: default !important;
}
table tr.table-row-expanded:hover {
  background-color: #fff;
}
table tr.table-row-expanded:hover .table {
  background-color: #fff;
}
table td.checked:after {
  content: "\f00c"; /* Check mark */
}
table th.actions {
  width: 2rem;
}
table td.actions, table td.action {
  padding-top: .2rem;
}
table td.actions span {
  margin-right: .5rem;
}
table td.actions span:last-child {
  margin-right: 0;
}
table th.editable.actions, table td.editable.actions {
  width: 10.75rem;
  white-space: nowrap;
}
table tr.edit-active td {
  vertical-align: top;
}
table tr.edit-active td .form-group.row {
  margin-bottom: 0;
}
table tr.edit-active td label.value {
  margin-top: 0.375rem;
}

.table td.comments button {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  width: 100%;
}

table .td-badge, .dropdown-item .badge {
  padding: 0.29rem 0.4rem 0.34rem;
  font-size: 90%;
  font-weight: 500;
  width: 100%;
}

/* Second child because Angular claims first th & td! */
.table.padding th:nth-child(2), .table.padding td:nth-child(2),
table.table-sm.padding th:nth-child(2), .table-sm.padding td:nth-child(2),
.table-xs.padding th:nth-child(2), .table-xs.padding td:nth-child(2) {
  padding-left: 1.5rem !important;
}

.table.padding th:last-child, .table.padding td:last-child,
.table-sm.padding th:last-child, .table-sm.padding td:last-child,
.table-xs.padding th:last-child, .table-xs.padding td:last-child {
  padding-right: 1rem;
}


/* Table layout / columns selector */
jbm-table-layout .popover-header {
  padding-top: 0.57rem;
  padding-bottom: 0.57rem;
  background-color: #e5e5e5;
}

jbm-table-layout .popover-body {
  padding: 1rem 2rem 1.5rem;
}

.popover.table-layout {
  max-width: none;
  width: 225px;
}

/* Pagination */
.page-item .page-link {
  border-right-width: 0;
  color: #1183b0;
}

.page-item:last-child .page-link {
  border-right-width: 1px;
}

.page-item .page-link:hover {
  background-color: #e2e6ea;
}


/* Module headers */

header.module {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4rem;
  padding-top: .7rem;
  padding-right: 5rem;
  padding-left: 1.5rem;
}

header.module:not(.subheader) {
  background-color: #fff;
}

header.module:not(.subheader) .pagination .page-item .page-link {
  font-weight: 400;
}
header.module:not(.subheader) .btn-link {
  padding-top: 0.4rem;
}
header.module:not(.subheader) .btn-primary:hover {
  background-color: #1183b0;
  border-color: #1183b0;
}
header.module:not(.subheader) .btn-outline-primary:hover {
  background-color: #149acf;
}
header.module:not(.subheader) .jbm-search-box i.fa-search {
  color: #999;
}
header.module:not(.subheader) .jbm-search-box.focused i.fa-search {
  color: #333;
}
header.module:not(.subheader) .jbm-search-box .form-control {
  font-weight: 600;
}
header.module:not(.subheader) .jbm-search-box .form-control:focus {
  background-color: #fff;
  color: #000;
}
header.module {
  background-color: #fff;
}
header.module:after, .sidebar .company:after {
  height: 4px;
  position: absolute;
  top: 100%;
  right: 0px;
  left: 0px;
  background: linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px);
  content: "";
}

header.module.subheader {
  position: static;
  padding: 0.5rem 0;
  height: 3.5rem;
  z-index: 0;
}

header.module.subheader .jbm-search-box {
  float: right;
}

.jbm-view-title, .jbm-crud-title {
  display: inline-block;
  float: left;
  line-height: 1.95;
  margin-right: 1.5rem;
}
.jbm-view-title .title span i.fas {
  opacity: .75;
  margin-right: .25rem;
}

.jbm-view-title .title, .jbm-crud-title .title {
  float: left;
  font-size: 1.2rem;
  font-weight: 400;
}

header:not(.module) .jbm-view-title .title {
  font-size: 1.1rem;
  padding-top: .2rem;
}

.jbm-view-title .title a {
  font-weight: 400;
}

.jbm-view-title.sm {
  font-size: 1rem;
}

.jbm-view-title.sm:not(.has-parent-slug) {
  padding-top: 0.54rem;
}

.jbm-view-title .count {
  float: right;
  margin-top: 0.2rem;
  margin-left: .50rem;
  font-size: 1rem;
}

.jbm-view-title.has-parent-slug {
  padding-top: 0;
  font-size: 1.1rem;
  line-height: .9;
}

.jbm-view-title.has-parent-slug .count {
  margin-top: 0;
}

.jbm-view-title .parent-slug a {
  display: inline-block;
  font-size: .85rem;
  line-height: 2;
}
.jbm-view-title .parent-slug a i.fa {
  color: #aaa;
  font-size: 0.7rem;
}
.jbm-view-title .separator {
  float: left;
  height: 1rem;
  margin: 0.1rem 0.5rem;
  border-left: 1px solid #ccc;
}
.jbm-view-title .sub-caption {
  float: left;
  margin-top: .15rem;
  font-size: 1rem;
  opacity: 0.9;
}

.toolbar {
  display: inline-flex;
  padding: .2rem 0;
}

.toolbar .toolbar-spacer-1 {
  width: 1rem;
}
.toolbar .toolbar-spacer-2 {
  width: 2rem;
}

.toolbar .jbm-view-title .title {
  font-size: 1rem;
  padding-top: .2rem;
}

.toolbar .jbm-date-range-input label {
  display: block;
}
.form-inline .jbm-date-range-input label {
  display: block;
}

.nav-tabs.nav.module {
  margin-left: -1.5rem;
  padding-left: 1.5rem;
  margin-right: -1rem;
  padding-right: 1rem;
}

header.module .nav-tabs.nav.left {
  left: 1.5rem;
}

header.module .nav-tabs.nav.right {
  right: 1.5rem;
}

header.module .header-section {
  float: left;
}

header.module .actions button {
  margin-right: 0.5rem;
}

header.module .actions button:last-child {
  margin-right: 0;
}


header.module .header-section .btn,
header.module .header-section .btn-group,
header.module .header-section .form-control,
header.module .header-section label {
  display: block;
  float: left;
}

header.module .header-section .form-control {
  width: auto;
}

header.module .header-section label {
  padding-top: 0.4rem;
  margin-right: 0.5rem;
}

header.module div[class^="header-spacer-"] {
  display: block;
  float: left;
  height: 34px;
}

header.module .header-spacer-1 {
  width: 1rem;
}

header.module .header-spacer-2 {
  width: 2rem;
}

header.module .btn-toolbar {
  float: left;
}

header.module .form-group {
  margin-bottom: 0;
}

header.module .pagination {
  float: right;
}

header jbm-table-layout {
  float: right;
}

header.module.breadcrumbs .jbm-view-title {
  float: none;
}

header.module.breadcrumbs .breadcrumbs {
  left: 1.5rem;
  margin-top: -.25rem;
}

.breadcrumbs {
  display: block;
}

.breadcrumb-item {
  float: left;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #999;
  content: "/";
}

.btn.round {
  height: 34px;
  width: 34px;
  border-radius: 50%;
}

.btn.round.btn-sm {
  height: 28px;
  width: 28px;
}
.btn.round .far, .btn.round .fas {
  margin-left: -.07rem;
}
.btn.round.btn-sm .fa {
  margin-left: -0.035rem;
}

section.module {
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #eeeeee54;
}

section.module.border-top {
  border-top: 1px solid #bbb !important;
}

section.module.padding {
  padding: 0 1rem 1rem 1.5rem;
}

section.module.padding-top {
  padding-top: 1rem;
}

section.module.overflow-y {
  overflow-y: auto;
}

section.module.subsection {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

/* Forms */

.modal-header .float-end .btn {
  margin-right: .5rem;
}

.modal-header .buttons {
  margin-left: 2rem;
}

.modal-header .float-end .btn:last-child {
  margin-right: 0;
}

.modal-header .buttons {
  float: right;
}

.modal-title {
  line-height: 1.75;
  font-size: 1.1rem;
}

.form-section-separator {
  margin-bottom: .5rem;
  padding-bottom: .25rem;
  border-bottom: 1px solid #dee2e6;
  font-weight: 500;
}

/* Dashboards */

.dashboard a {
  display: block;
  padding-bottom: 1rem;
}
.form-group.row > * {
  width: auto;
}
.form-group.stacked {
  margin-bottom: .75rem;
}
.form-group.row.stacked {
  flex-wrap: wrap;
}

.form-group.stacked [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.form-group.stacked [class*="col-"]:first-child {
  margin-bottom: 0;
}

.form-group.stacked .form-control, .form-group.stacked .ng-invalid .invalid-feedback, .form-group.stacked .input-group {
  margin-left: 0;
}

.form-group.stacked label:first-child {
  width: 100% !important;
  margin-bottom: 0.1rem;
}

.form-group .form-text {
  display: inline-block;
  margin-left: .5rem;
  margin-bottom: .25rem;
}

.input-group-text {
  background-color: #eef1f3;
  border-radius: 0;
  border: none;
  line-height: 1.95;
}

.input-group {
  flex-wrap: unset;
}

.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
  width: inherit;
}

/* Domain specific input classes */
.width-40 { width: 40rem !important; }
.width-39 { width: 39rem !important; }
.width-38 { width: 38rem !important; }
.width-36 { width: 36rem !important; }
.width-34 { width: 34rem !important; }
.width-33 { width: 33rem !important; }
.width-30 { width: 30rem !important; }
.width-29 { width: 29rem !important; }
.width-27 { width: 27rem !important; }
.width-25 { width: 25rem !important; }
.width-15 { width: 15rem !important; }
.width-11 { width: 11rem !important; }
.width-10 { width: 10rem !important; }
.width-9  { width: 9rem !important; }
.width-8  { width: 8rem !important; }
.width-7  { width: 7rem !important; }
.width-6  { width: 6rem !important; }

.input-container {
  padding: 0
}

.form-group.xxl .input-container .form-control, .form-group.lg .input-group {
  width: 31rem;
}
.form-group.xl .input-container .form-control, .form-group.lg .input-group {
  width: 30rem;
}
.form-group.lg .input-container .form-control, .form-group.lg .input-group {
  width: 27rem;
}
.form-group.md .input-container .form-control, .form-group.md .input-group {
  width: 22rem;
}
.form-group.sm .input-container .form-control, .form-group.sm .input-group {
  width: 19rem;
}
.form-group.xs .input-container .form-control, .form-group.xs .input-group {
  width: 13rem;
}
.form-group.xxs .input-container .form-control, .form-group.xxs .input-group {
  width: 10rem;
}
.form-group.xxxs .input-container .form-control, .form-group.xxxs .input-group {
  width: 8rem;
}


.form-group .controls.xs {
  width: 11rem;
}
.form-group .controls.xxs {
  width: 8.5rem;
}


.form-group .col-form-label.xl {
  width: 11rem;
}
.form-group .col-form-label.lg {
  width: 9.5rem;
}
.form-group .col-form-label.md {
  width: 9rem;
}
.form-group .col-form-label.sm {
  width: 6rem;
}
.form-group .col-form-label.xs {
  width: 5rem;
}
.form-group .col-form-label.xxs {
  width: 2rem;
}

/* Label + Control combinations */
.lg-lg {
  width: 36.75rem;
}
.md-md {
  width: 31.5rem;
}
.md-xl {
  width: 36.5rem;
}

fieldset .form-group label.col-form-label.md {
  width: 7rem;
}


.form-group.time .controls {
  width: 11rem;
}
.form-group.date .controls {
  width: 9.5rem;
}

.form-group.input-36 .input-container .form-control, .form-group.input-36 .input-group {
  width: 36rem;
}

.form-group.input-30 .input-container .form-control, .form-group.input-30 .input-group {
  width: 30rem;
}

.form-group.input-28 .input-container .form-control, .form-group.input-28 .input-group {
  width: 28rem;
}

.form-group.input-27 .input-container .form-control, .form-group.input-27 .input-group {
  width: 27rem;
}

.form-group.input-25 .input-container .form-control, .form-group.input-25 .input-group {
  width: 25rem;
}

.form-group.input-23 .input-container .form-control, .form-group.input-23 .input-group {
  width: 23rem;
}

.form-group.input-22 .input-container .form-control, .form-group.input-22 .input-group {
  width: 22rem;
}

.form-group.input-20 .input-container .form-control, .form-group.input-20 .input-group {
  width: 20rem;
}

.form-group.input-15 .input-container .form-control, .form-group.input-15 .input-group {
  width: 15rem;
}

.form-group.input-13 .input-container .form-control, .form-group.input-13 .input-group {
  width: 13rem;
}
.form-group.input-12 .input-container .form-control, .form-group.input-12 .input-group {
  width: 12rem;
}

.form-group.input-10 .input-container .form-control, .form-group.input-10 .input-group {
  width: 10rem;
}

.form-group.input-9 .input-container .form-control, .form-group.input-9 .input-group {
  width: 9rem;
}

.form-group.input-8 .input-container .form-control, .form-group.input-8 .input-group {
  width: 8rem;
}

.form-group.input-7 .input-container .form-control, .form-group.input-7 .input-group {
  width: 7rem;
}

.form-group.input-6 .input-container .form-control, .form-group.input-6 .input-group {
  width: 6rem;
}

/* Form fields en headers */

select.form-control {
  padding-left: .5rem;
}

.form-group.row {
  margin-left: -.75rem;
  margin-right: 0;
}

.form-group .ng-invalid .invalid-feedback {
  margin-left: .5rem;
  margin-bottom: .5rem;
}

.invalid-feedback.is-invalid {
  display: block;
}

.form-group.readonly .input-group-text {
  background-color: transparent;
}

.form-group textarea:disabled {
  background-color: #fff;
  font-weight: 500;
}

.form-group .data-value .col-form-label, fieldset .form-group .data-value .col-form-label {
  margin-left: .5rem; /* No negative margin needed for datavalue labels inside fieldsets */
  font-weight: 500;
}

.form-control, .btn {
  font-size: .8rem;
}

.form-check-label {
  margin-top: 0!important;
  padding-top: .5rem;
  padding-left: .2rem;
}
.form-check {
  padding-left: .5rem;
}
.input-container .form-check {
  padding-left: 1.25rem;
}
.form-check.form-check-inline .form-check-label {
  padding-top: .25rem;
}
.form-check-input {
  margin-top: 0.68rem;
}

.toolbar .form-check.form-check-inline {
  margin-top: -0.4rem;
}

.form-control {
  border-color: #dee2e6;
}

label span.asterix {
  vertical-align: middle;
  padding-left: 2px;
  color: #a01c28;
  line-height: 1;
}

form .form-group {
  margin-bottom: .4rem;
}

.form-group .form-control, .form-group .input-group {
  margin-left: .5rem;
}

.form-group .form-check-input {
  margin-left: -.75rem;
}

.form-group .input-group .form-control {
  margin-left: 0;
}

.form-group.readonly .input-group-text {
  padding-left: .25rem;
  padding-right: 0;
}

/* Validation states */

select.form-control.is-invalid {
  /* Make space for dropdown arrow */
  background-position: right calc(1rem + 0.1875rem) center;
}

.form-control.align-right.is-invalid, .form-control.align-right.ng-dirty.ng-valid {
  padding-left: calc(1.5em + .75rem);
/*  padding-right: 15px;*/
  /* Align icon left */
  background-position: left calc(.4rem) center;
}

.form-control.readonly {
  background-color: #fff;
}
.form-control:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.was-validated .form-control:invalid, .form-control.ng-touched.ng-invalid {
  color: #dc3545;
}

.form-control.ng-dirty.ng-invalid ~ .invalid-feedback {
  display: block;
}

.form-control.ng-dirty.ng-valid {
  border-color: #28a745;
/*  padding-right: calc(1.5em + .75rem);*/
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
/*  background-position: right calc(.375em + .1875rem) center; */
  background-position: right calc(0.25rem) center;
/*  background-size: calc(.75em + .375rem) calc(.75em + .375rem);*/
}

select.form-control.ng-dirty.ng-valid {
  /* Add space for dropdown arrow */
  background-position: right calc(1rem + 0.1875rem) center;
}
.input-group .form-control.ng-dirty.ng-valid, .input-group .form-control.ng-dirty.ng-invalid {
  margin-right: 1px;
}

.custom-control-label {
  padding-top: 0.23rem;
}
.custom-control-label::before {
  position: absolute;
  top: 0.4rem;
}
.custom-control.custom-switch {
  margin-top: .1rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.4rem + 2px);
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #15a6df;
  background-color: #15a6df;
}



/* Tabsets */

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  font-weight: 600;
}

.tab-content .tab-pane {
  padding-top: .75rem;
}

/* Popup */

.popup {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  background-color: #fff;
  z-index: 1900;
}
body.show-popup .popup {
  display: block;
}

/* Domain specific */

/* Dashboard */

.dashboard-layout {
  display: block;
  width: 100%;
  box-sizing: border-box;
  .grid-container {
    padding: 4px;
    box-sizing: border-box;
    border: 1px solid gray;
    border-radius: 2px;
    background: #313131;
  }
  .card {
    height: 100%;
  }
  ktd-grid {
    transition: height 500ms ease;
  }

  ktd-grid-item {
    .handle-1 {
      cursor: grab;
    }
    &.ktd-grid-item-dragging {
      .handle-1 {
        cursor: grabbing;
      }
    }
  }
  // customize placeholder
  ::ng-deep .ktd-grid-item-placeholder {
    background-color: #ffa726;
  }
}

/* Company and plants */
form.company .toolbar.buttons {
  position: absolute;
  right: 0;
  top: 0.5rem;
  padding: 0;
}

.company .modal-header {
  padding: 0;
  border-bottom: none;
}

/* Customers */

.jbm-table.customers th.actions {
  width: 6rem;
}

form.customer .left-side, form.customer .right-side {
  float: left;
  width: 31.5rem;
}
form.customer .left-side {
  margin-right: 5rem;
}

.jbm-properties-list.customer-details .label {
  width: 8rem;
}
.jbm-properties-list.customer-details .value {
  max-width: 28rem;
}
.jbm-properties-list.customer-address .label {
  width: 10rem;
}

.jbm-modal.customer-details  {
  width: 55rem;
}

.customer-selector .selector-list {
  min-width: 30rem;
}

/* Financial */

.jbm-modal.uses {
  width: 40rem;
}

.jbm-properties-list.uses label.label {
  width: 10rem;
}

table.uses td.action {
  width: 3rem;
}

.uses-table-container {
  max-height: 10rem;
  overflow-y: auto;
}

.table.article_price_types .code {
  width: 12rem;
}

.table-container#article-prices {
  top: 30px;
}

.table.article_prices .price {
  text-align: right;
}

.jbm-modal.article-price, .jbm-modal.concrete-price {
  width: 30rem;
}

.table.article-pricelists th.actions, .table.concrete-pricelists th.actions {
  width: 10rem;
}

.table.article-pricelists .action-delete-container, .table.concrete-pricelists .action-delete-container {
  width: 4rem;
}

.module.concrete-prices table td.strength {
  padding-top: 0.45rem;
}

.jbm-properties-list.article-pricelist .label,
.jbm-properties-list.article .label {
  width: 8rem;
}

.jbm-properties-list.concrete-pricelist .label {
  width: 126px;
}

.copy-article-pricelist .jbm-properties-list .label {
  width: 8rem;
}
.copy-article-pricelist .jbm-properties-list.customer .label {
  width: 7rem;
}

.jbm-modal.connect-pricelist, .jbm-modal.assign-projects {
  width: 40rem;
}

.jbm-modal.copy-resource-pricelist .jbm-modal-body {
  height: 20rem;
}

/* Price structure popover */

.popover {
  max-width: 314px;
}
.popover-body table.pricerules-breakdown th {
  font-weight: 400;
}
.popover-body table.pricerules-breakdown th.entity {
  width: 10rem;
}
.popover-body table.pricerules-breakdown th.price {
  width: 5rem;
}

/* Price history */

.dropdown-menu.price-history {
  width: 45rem;
}
.article-price-history-container {
  max-height: 11rem;
  overflow-y: auto;
}
.table.article-price-history th.pricelist-type {
  width: 10rem;
}
.table.article-price-history th.price {
  width: 5rem;
}

/* Recipes & resources */

.jbm-modal.recipe-details {
  max-height: 35rem;
}
.jbm-modal.recipe-details .left {
  float: left;
  width: 25rem;
}
.jbm-modal.recipe-details .right {
  float: left;
  width: 15rem;
}
.jbm-modal.recipe-details .jbm-properties-list.fields label.label {
  width: 7rem;
}
.jbm-modal.recipe-details .jbm-properties-list.specs label.label {
  width: 6rem;
}

/* Recipe form */
.jbm-modal.recipe .jbm-modal-body {
  min-width: 39rem;
  min-height: 24rem;
  max-height: 32rem;
}

table.recipe-resources th.amount, table.recipe-articles th.amount {
  width: 8rem;
}
table.recipe-resources th.action, table.recipe-articles th.action {
  width: 5rem;
}

.jbm-modal.resource-price {
  width: 49rem;
}

.concrete-pricelist-selector .selector-list, .article-pricelist-selector .selector-list {
  min-width: 30rem;
}


/* Quotations */

table.quotation-revisions {
  width: auto;
}
table.quotation-revisions th {
  background-color: #fff;
}

.jbm-modal.revision {
  width: 28rem;
}

.jbm-modal.quotation-send .html-body {
  position: relative;
  height: 12rem;
  overflow-y: auto;
}

/* Invoice details */

.quotation-details .expandable button.toggle {
  width: 2rem;
}
.quotation-details .jbm-properties-list .label {
  width: 6.5rem;
}
.quotation-details .jbm-properties-list.collapsed .label {
  width: auto;
}
.quotation-details .jbm-properties-list .jbm-comment-box.customer {
  max-width: 30rem;
}
.quotation-details table.select-project .label {
  width: 4rem;
}
.quotation-details table.select-project .form-group {
  margin-bottom: 0;
}
.quotation-details table.select-project .form-group .form-control {
  margin-left: 0;
}

.table.quotation-items th.strength {
  width: 5rem;
}
.table.quotation-items th.environment {
  width: 4rem;
}
.table.quotation-items th.consistency,.table.quotation-items th.amount,
.table.quotation-items th.price, .table.quotation-items th.totalprice {
  width: 6rem;
}
.table.quotation-items .action-buttons {
  width: 7rem;
}

.jbm-modal.quotation-item {
  width: 63rem;
}

/* Projects */

.jbm-table.projects th.actions {
  width: 5rem;
}

/* Project selector */
.selector-list.projects {
  width: 50rem;
}


/* Projectview */

.projectview .toolbar {
  display: block;
  width: 100%;
}
.projectTotalPrice {
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 600;
}

.project-details .header, .order-details .header {
  height: 2.2rem;
  margin-bottom: .2rem;
  border-bottom: 1px solid #149acf;
  line-height: 1.2;
}
.project-details .header label.value, .order-details .header label.value {
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-details label.top, .order-details label.top, .invoice-validation label.top {
  display: block;
  margin-bottom: 0;
  font-size: 80%;
}
.project-details label.value, .order-details label.value, .invoice-validation label.value {
  color: #000;
}
.project-details section, .order-details section, .invoice-validation section {
  float: left;
  padding-right: 1rem;
  min-height: 1rem;
}
.project-details section:last-child, .order-details section:last-child, .invoice-validation section:last-child {
  padding-right: 0;
}
.project-details section.project {
  width: 16%;
}
.project-details section.project .btn, .order-details section.worktype .btn, .order-details section.recipe .btn  {
  float: left;
  margin-right: 2%;
}
.project-details section.customer {
  width: 30%;
}
.project-details section.work-location {
  width: 25%;
}
.project-details section.plant {
  width: 25%;
}
.projectdetails {
  border-top: 1px solid #15a6df;
}
.projectdetails div.column {
  float: left;
  width: 31%;
  margin-right: 2rem;
  padding-left: 1rem;
  border-left: 1px solid #eee;
}
.projectdetails div.column:first-child {
  padding-left: 0;
  border-left: none;
}
.projectdetails div.column:last-child {
  margin-right: 0;
}
.projectdetails div.column td {
  vertical-align: top;
  padding-left: 0;
}
.projectdetails div.column tr:first-child td {
  padding-top: 0;
}
.projectdetails div.column tr:last-child td {
  padding-bottom: 0;
}
.projectdetails div.column.location td.address {
  line-height: 1.85;
}
.projectdetails div.column.location td.label {
  width: 4rem;
}
.projectdetails div.column.customer td.label {
  width: 6rem;
}
form.project .left-side {
  float: left;
  width: 40rem;
}
form.project .right-side {
  float: left;
  width: 27.75rem;
}


ngb-popover-window.order-articles-list {
  width: 35rem;
  max-width: inherit;
}
ngb-popover-window.order-articles-list th.type {
  width: 5rem;
}
ngb-popover-window.order-articles-list th.amount {
  width: 6rem;
}

/* ng-datepicker */
.ngb-dp-content.ngb-dp-months {
  background-color: #fff;
}
.ngb-dp-weekday {
  color: #333 !important;
}
.ngb-dp-weekday, .ngb-dp-week-number {
   font-style: normal !important;
 }

.jbm-modal.project-details {
  width: 57rem;
}
.jbm-modal.project-details .jbm-properties-list.project-details label.label {
  width: 9rem;
}
.jbm-modal.project-details .jbm-properties-list.work-location label.label {
  width: 10rem;
}

@media (max-width: 1320px) {
  form.project .right-side { width: 34rem }
}


/* Orders */

.order-details .body {
  clear: both;
}
.order-details .view-section-title .dropdown {
  float: left;
}
.order-details section.worktype {
  width: 16%;
}
.order-details section.recipe {
  width: 30%;
}
.order-details section.recipe button.btn-link {
  width: 100%;
}
.order-details section.unloading-method {
  width: 20%;
}
.order-details section.unloading-flow {
  width: 9%;
}
.order-details section.production {
  width: 25%;
}
.order-details .view-section-title .actions {
  width: 30.25rem;
}
.order-details .view-section-title .filler {
  width: 2.5rem;
}
.order-details .progress {
  position: relative;
  width: 15rem;
  height: 1.3rem;
  background-color: #bbb;
}
.order-details .progress.production {
  width: 6rem;
}
.order-details .progress-info {
  position: absolute;
  font-size: 90%;
  padding-top: 0.1rem;
  padding-left: 1rem;
  color: #fff;
}

.order-details .articles .jbm-empty-view {
  border-top: none;
}

.order-details .title {
  padding: 0.5rem 0rem 0.5rem 0;
  border-bottom: 1px solid #149acf;
}
.jbm-modal.order-details .title {
  border-bottom: none;
}

.order-details .title .price {
  font-weight: 500;
}

.jbm-properties-list.order-details label.label {
  width: 7rem;
}
.jbm-properties-list.order-details label.value {
  width: 15rem;
}

.cards.orders .card {
  display: block;
  position: relative;
  border-width: 0;
  margin-bottom: 1rem;
}
.cards.orders .card.showprices {
  padding-bottom: 7.5rem;
}
.cards.orders .card-header {
  background-color: #fff;
  border-radius: 0;
  border-top: 1px solid #15a6df;
}
.cards.orders .card-header button.toggler {
  margin-top: -.3rem;
}

.cards.orders .card .column {
  float: left;
}

.cards.orders .card .column:last-child {
  margin-right: 0;
}

.cards.orders .card .column {
  padding: .5rem 1rem;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.cards.orders .card .column:first-child {
  border-left: none;
}

.cards.orders .card .column.one {
  width: 37%;
}

.cards.orders .card .column.one tr td.value {
  width: 80%;
}

.cards.orders .card .column.two {
  width: 23%;
}

.cards.orders .card .column.two tr td:not(.value) {
  width: 6rem;
}

.cards.orders .card .column.three {
  width: 22%;
  margin-right: 1rem;
}

.cards.orders .card .column.four {
  width: 16%;
}

.cards.orders .table.details td {
  padding-left: 0
}

.cards.orders .table.details td.button {
  padding: 0;
}

.cards.orders .table.details td.comments button {
  width: 20rem;
}

.cards.orders .comments-box {
  position: relative;
  height: 3.2rem;
  overflow-y: auto;
  padding: .5rem 1rem;
  padding-left: 2.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.cards.orders .comments-box:after {
  font: normal normal normal .8rem/1 FontAwesome;
  content: "\f0e6"; /* Comments */
  position: absolute;
  left: 1rem;
  top: .75rem;
  color: #aaa;
}

.cards.orders div.totals {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: 700;
  padding: .45rem;
}
.cards.orders div.totals div.float-end {
  margin-right: 7.35rem;
  width: 11rem;
}

.cards.orders div.totals .table {
  margin-right: 6.55rem;
}
.cards.orders div.totals hr {
  margin:0;
}
.cards.orders div.totals .concrete {
  margin-right: 6.55rem;
}

/*.table.order th label.value, .table.order td label.value {
  margin-top: .32rem;
}*/
.table.order th.action-buttons {
  width: 10rem;
}

form.order .left-side {
  float: left;
  width: 43rem;
}
form.order .right-side {
  float: left;
  width: 28.5rem;
}
@media (max-width: 1320px) {
  form.order .right-side { width: 35rem }
}

/* Recipe selector */
.selector-list.recipes {
  width: 60rem;
}

/* Planning */

.module.planning select.plant {
  width: auto;
}

.module.planning .period-totals {
  margin-right: 2rem;
  padding-top: .35rem;
}
.module.planning .period-totals .badge-pill {
  padding-bottom: .1rem;
  font-size: 90%;
}

.planning-chart .header {
  width: 100%;
}
.planning-chart .header .date-time {
  float: left;
  margin-right: 2rem;
  padding-top: .65rem;
}
.planning-chart .header .date-range {
  float: left;
  width: 12rem;
  font-weight: 600;
}
.planning-chart .header .time {
  float: left;
  margin-left: 2rem;
  width: 4.5rem;
  font-weight: 100;
}
.planning-chart .header .time .far {
  opacity: .5;
}
.planning-chart .header .header-content {
  float: left;
  margin-left: 2rem;
}
.planning-chart .header .controls {
  float: right;
  padding-top: .25rem;
}
.dropdown-menu.table-cell.planning-legend {
  width: 20rem;
}

.planning-chart .header .controls .navigation-controls {
  float: right;
}
.planning-chart .header .controls .view-controls {
  float: right;
  margin-right: 1rem;
}
.planning-chart .timeline {
  padding: .5rem 0 .25rem 0;
  border-top: 1px solid #f1f3f5;
  border-bottom: 1px solid #149acf;
}
.planning-chart .timeline .tick {
  position: relative;
  height: 1.25rem;
  float: left;
  font-size: 80%;
}
.planning-chart .timeline .tick.day {
  width: 4.16666666667%;
}
.planning-chart .timeline .tick .marker  {
  position: absolute;
  top: 19px;
  height: 5px;
  border-left: 1px solid #149acf;
}
.planning-chart .timeline .tick .marker.first {
  top: 15px;
  height: 9px;
}
.planning-chart .timeline .tick.week {
  width: 14.2857142857%;
}
.planning-chart .timeline .tick.month-28,
.planning-chart .chart.tasks .day-column.month-28 {
  width: 3.57142857143%;
}
.planning-chart .timeline .tick.month-29,
.planning-chart .chart.tasks .day-column.month-29 {
  width: 3.44827586207%;
}
.planning-chart .timeline .tick.month-30,
.planning-chart .chart.tasks .day-column.month-30 {
  width: 3.3333333333%;
}
.planning-chart .timeline .tick.month-31,
.planning-chart .chart.tasks .day-column.month-31 {
  width: 3.22580645161%;
}

.planning-chart .task-line {
  position: relative;
  height: 1.75rem;
  line-height: 1.8;
  border-bottom: 1px solid #f1f3f5;
}
.planning-chart .task-line .taskTooltip-line {
  text-align: left;
  line-height: 1;
}
.planning-chart .task-line .table.tooltip-table td {
  color: #fff;
  border-width: 0;
  text-align: left;
}

.planning-month-tooltip {
  width: 25rem;
}
.planning-chart .task-line .taskTooltip-line label.value {
  display: inline-block;
  width: 3rem;
  text-align: right;
  padding-left: .5rem;
  font-weight: 600;
}

.planning-chart .tasklist .task-line {
  height: auto;
  padding-top: 15px;
}
.planning-chart .tasklist .task-line .task {
  height: 15px;
  cursor: default;
}
.planning-chart .task-line .task .task-progress {
  height: 100%;
  text-align: center;
}

.planning-chart .tasklist .task-line .task .details .column:last-child {
  margin-right: 0;
}
.planning-chart .task-line.selected {
  border-bottom: none;
}
.planning-chart .chart.tasks {
  position: relative;
}
.planning-chart .chart.tasks .time-marker {
  position: absolute;
  top:0;
  width: 1px;
  height: 100%;
  border-left: 2px dashed #ff9999;
}
.planning-chart .task-line.day .task, .planning-chart .tasklist .task-line .task {
  top: 3px;
  cursor: pointer;
  opacity: .7;
  z-index: 1;
}
.planning-chart .task-line.day .label {
  line-height: 1.75;
  font-size: 80%;
  font-weight: 600;
  white-space: nowrap;
}
.planning-chart .task-line.day .task .task-progress {
  text-align: center;
  line-height: 1;
}
.planning-chart .task-line .task .amount {
  position: absolute;
  top: 0;
  right: .5rem;
}
.planning-chart .tasklist .task-line .task .amount {
  top: 0;
  right: -6.5rem;
}

.planning-chart .task-line.day .task-middle {
  line-height: 2rem;
  height: 2rem;
}
.planning-chart .task-line.day .task-middle .task-progress {
  text-align: center;
}
.planning-chart .task-line.day .task-middle .label {
  line-height: 2;
  font-size: 80%;
  font-weight: 600;
  white-space: nowrap;
}
.planning-chart .task-line.day .task-large {
  position: absolute;
  height: 2rem;
}
.planning-chart .task-line.day .task-large .task-progress .label {
  position: absolute;
  right: 1rem;
  top: .4rem;
}
.planning-chart .task-line.day .task-large .projectname {
  float: left;
  padding-left: .5rem;
  margin-top: .25rem;
}
.planning-chart .task-line.day .task-large .label.amount-flow {
  float: left;
  margin-top: .42rem;
  padding-left: .5rem;
}
.planning-chart .task-line.day .task-left, .planning-chart .task-line.day .task-right {
  line-height: 1;
  height: 2rem;
}
.planning-chart .task-line.day .task-left .projectname, .planning-chart .task-line.day .task-right .projectname {
  padding-top: .1rem;
  font-size: 95%;
}
.planning-chart .task-line.day .task .amount-flow {
  line-height: 1.2;
}


.planning-chart .task-line.month {
  margin-top: 0.1rem;
}
.planning-chart .task-line.month .task .amount {
  position: absolute;
  top: .25rem;
  left: 0;
  text-align: center;
  width: 100%;
}
.planning-chart .task-line .task .flow, .planning-chart .task-line .task .amount-flow {
  position: absolute;
  top: 1.1rem;
  right: 0;
  white-space: nowrap;
  color: #000;
}
.planning-chart .task-line .task .amount-flow {
  left: 0;
  width: 8rem;
}
.planning-chart .tasklist .task-line .task .label {
  font-size: 80%;
  font-weight: 700;
}
.planning-chart .tasklist .task-line .task .amount-flow {
  top: -2px;
  left: auto;
  right: -6rem;
}
.planning-chart .tasklist .task-line .task .amount-flow.left {
  left: -6rem;
  right: auto;
}
.planning-chart .tasklist .task-line .task .delivered {
  position: absolute;
  top: -2px;
  left: .5rem;
}
.planning-chart .task-line.selected, .planning-chart .task-line-details.selected,
.planning-chart .task-line.week:hover {
  background-color: #e5f6fc;
}
.planning-chart .task-line-details {
  padding: .5rem 0 0;
}
.planning-chart .task-line-details.selected {
  padding: .5rem .5rem 0;
}

.planning-chart .task-line.day {
  height: 3.75rem;
}
.planning-chart .task-line.day.selected .btn.round {
  position: absolute;
  right: .5rem;
  top: .25rem;
}

.planning-chart .task-line.week {
  height: 6.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #149acf;
  font-size: 80%;
  font-weight: 600;
  cursor: pointer;
}
.planning-chart .task-line.week .task {
  position: absolute;
  top: auto;
  bottom: 1rem;
  height: 1.25rem;
  cursor: pointer;
  opacity: .7;
  z-index: 1;
}
.planning-chart .task-line .task .amount-flow {
  padding-left: 0;
  width: auto;
  text-align: right;
}
.planning-chart .task-line.day {
  height: 2rem;
}
.planning-chart .task-line.day.selected {
  height: 2.5rem;
}
.planning-chart .chart .task-line.day .task {
  top: 22px;
  height: 1.25rem;
}
.planning-chart .chart .task-line.day.selected .task {
  top: 5px;
}
.planning-chart .chart .task-line.month .task {
  height: 100%;
  width: 100%;
  font-size: 80%;
  cursor: pointer;
}
.planning-chart .chart .task-line.month .task:hover {
  background-color: #e5f6fc !important;
  color: #000 !important;
}
.planning-chart .task-line .task .projectname {
  position: absolute;
  top: -1.5rem;
  width: 100%;
  min-width: 75px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.planning-chart .chart.tasks .day-column {
  float: left;
  width: 14.2857142857%;
  padding: 0 .08rem;
}
.planning-chart .chart.tasks .day-column.month {
  text-align: center;
}
.planning-chart .chart.tasks .day-column.month .day-totals {

  height: 2.25rem;
}
.planning-chart .chart.tasks .day-column.month .day-totals .badge {
  font-weight: 400;
  display: block;
  margin-top: .1rem;
}
.planning-chart .chart.tasks .day-column .times {
  height: 1.25rem;
}
.planning-chart .chart.tasks .day-column .projectname {
  line-height: 1.5;
}

/* Order details modal / panel */

.task-line-details .planning-order-details {
  padding: .5rem;
}

.jbm-modal.order-details {
  width: 75rem;
}
.jbm-modal.order-details-shared {
  width: 50rem;
}
.jbm-modal.order-details .jbm-modal-body {
  margin-bottom: 0;
  padding-right: 1rem;
}
.planning-order-details section {
  float: left;
  width: 32%;
  margin-right: 1rem;
}
.planning-order-details section:last-child {
  margin-right: 0;
}
.planning-order-details .projectstate .label {
  float: left;
  width: 10rem;
}
.planning-order-details label.top {
  display: block;
  margin-bottom: 0;
  font-size: 80%;
  line-height: 1;
}
.planning-order-details .planning-task {
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.planning-order-details .planning-task div {
  float: left;
}
.planning-order-details .planning-task .date {
  width: 10.1rem;
}
.planning-order-details .planning-task .times {
  width: 10.3rem;
}
.planning-order-details .planning-task .times .time {
  float: left;
  width: 3rem;
}
.planning-order-details .planning-task .amounts div {
  margin-right: 1rem;
}
.planning-order-details .planning-task .amounts div:last-child {
  margin-right: 0;
}
.planning-order-details .planning-task .progressbar1 {
  width: 55%;
  padding-top: 1rem;
}
.planning-order-details .planning-task .progressbar1 .task-progress {
  font-size: 80%;
  text-align: center;
  line-height: 1.5;
}
.planning-order-details .header {
  height: 2.5rem;
  border-bottom: 1px solid #ddd;
}
.planning-order-details .header label.value {
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.planning-order-details .body {
  clear: both;
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ddd;
}
.planning-order-details .body {
  padding-top: 0.5rem;
  line-height: 1.5;
}
.planning-order-details .body label.top {
  line-height: 1;
}
.planning-order-details .body .has-button button {
  margin-top: .25rem;
}
.planning-order-details .body .recipe .buttons button {
  float: left;
  margin-right: 2%;
}
.planning-order-details .actions .dropdown {
  display: inline-block;
}
.planning-order-details .footer {
  clear: both;
  padding-top: .75rem;
  padding-bottom: 0.75rem;
}
.planning-order-details .footer .task-actions {
  width: 25rem;
}

/* Inside planning embedded Plan order form  */

form.plan-order {
  border-bottom: 1px solid #149acf;
}
form.plan-order .form-group {
  margin-bottom: 0;
}
form.plan-order .toolbar {
  padding: 0;
}

/* truck-capacity indication */

header.planning .truck-capacity {
  height: 1.15rem;
  margin-top: .4rem;
}

.truck-capacity-indication .panel {
  top: 3.75rem;
  right: 1rem;
  width: 56rem;
  height: 14rem;
}
.truck-capacity-indication .panel .plant {
  overflow-x: auto;
}
.truck-capacity-indication .capacity-chart {
  display:block;
  width:600px;
  height:300px;
  margin-bottom:1em;
}
.truck-capacity-indication .capacity-chart .chart-container {
  height: 100%;
}
.truck-capacity-indication .capacity-chart .chart-container .chart-bar {
  float: left;
  width: 2rem;
  background-color: #999;
}
.truck-capacity-indication .hour-label {
  position: absolute;
  bottom: 2rem;
  left: 46%;
}
.truck-capacity-indication .footer button {
  margin-top: -1.5rem;
}

/* ngx-charts */
.ngx-charts-outer {
  height: 100%;
}
.chart-legend .legend-title-text {
  line-height: 18px !important;
  font-weight: 400;
}
.ngx-charts .line-highlight {
  display: block !important;
}

.instant-production-order .jbm-properties-list label.label {
  width: 11.5rem;
  text-align: right;
}

/* Production */

section.production-receipts .receipt-totals {
  clear: both;
  min-height: 1rem;
}
section.production-receipts .receipt-totals .total {
  margin-top: -1px;
}
section.production-receipts .table-container, section.synchronise-receipts .table-container {
  top: 69px;
}
section.synchronise-receipts .synchronise {
  width: 27.25rem;
}
.jbm-modal.synchronise-order {
  width: 59rem;
}
.jbm-modal.synchronise-order .jbm-modal-body {
  min-height: 23rem;
}

.jbm-modal.drop-receipt {
  width: 32rem;
}

/* Logistics */
.selector-container .selector-list.trucks {
  width: 30rem;
}
.selector-container .selector-list.trucks.plant {
  height: 28rem;
}
.selector-list.drivers {
  width: 26rem;
}
.drivers-view {
  padding-left: 18rem;
}
.trucks-view {
  padding-left: 3rem;
}

/* Presentations */

.table.presentations th.actions {
  width: 9rem;
}
.table.presentations button.editor {
  width: 6rem;
}
.table.presentations button.attachments {
  width: 7.5rem;
}


.template-editor .editor-header {
  padding: .25rem 1.5rem;
}
.template-editor .editor-header h5 {
  margin-bottom: 0.25rem;
}

.code-editor .message, .code-editor .subject {
  height: 100%;
}

.code-editor .card-header {
  padding: 0 0 0 1rem;
  min-height: 43px;
}

.code-editor .card-header .btn.btn-link {
  width: 100%;
}

.code-editor .card-body {
  padding: 0;
}

.code-editor .alert {
  margin-bottom: 0;
}

.cm-s-default .cm-string {
  color: blue !important;
}

.pdf-viewer {
  height: 100%;
  width: 100%;
}
.pdf-viewer object {
  height: 100%;
  width: 100%;
}

.template-placeholder-picker {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0rem;
  right: 0;
  bottom: 0;
  width: 35rem;
  padding: 1rem;
  padding-top: .75rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  z-index: 10;
}
.template-placeholder-picker .header {
  height: 4rem;
}
.template-placeholder-picker .header .btn.round {
  margin-top: -.5rem;
}
.template-placeholder-picker .header .nav-tabs {
  clear: both;
}
.template-placeholder-picker .body {
  overflow-y: auto;
}
.template-placeholder-picker .table td {
  padding-left: 0;
  padding-right: 0;
}
.template-placeholder-picker .table td.placeholder {
  width: 15rem;
}
.placeholder {
  cursor: default;
  background-color: #fff;
  opacity: 1;
}
.template-placeholder-picker .body .image {
  float: left;
  width: 30%;
  height: 14rem;
  margin-right: 1rem;
}
.template-placeholder-picker .body .image button {
  width: 100%;
}
.template-placeholder-picker .body .image-container {
  display: table-cell;
  height: 7rem;
  vertical-align: middle;
  text-align: center;
}
.template-placeholder-picker .body .image img {
  max-width: 100%;
  margin-top: .5rem;
  margin-bottom: .5rem;
}
.template-placeholder-picker .body .image .description {
  height: 2rem;
  margin-top: .5rem;
}

/* Mail template editor */

#subject .CodeMirror {
  height: 65px;
}

.content-secondary.mail-viewer-container {
  position: relative;
  height: 100%;
}
.mail-viewer {
  position: absolute;
  top: 0;
  left: 0;
  right: 1rem;
  bottom: 0;
  padding: 1rem;
}
.mail-viewer.borderless {
  padding: 0;
}

.selector-list.presentations {
  width: 34rem;
}

/* Mediafiles */

table.mediafiles tr.table-row-expanded td {
  height: 20rem;
  vertical-align: top;
}
table.mediafiles tr.table-row-expanded td .jbm-document-viewer {
  height: 100%;
}
.document-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 100;
}
.document-preview .jbm-document-viewer {
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  bottom: 0;
}
.document-preview .header {
  height: 4rem;
  padding: 1rem 1.5rem;
}
.document-preview .header .title {
  margin-top: .35rem;
}
/* Mediafile form */

.jbm-modal.mediafile {
  width: 37.5rem;
}
.jbm-modal.mediafile .jbm-modal-body {
  height: 17rem;
}
form.mediafile .upload {
  padding-left: 9.5rem;
}

form.mediafile .upload .form-text {
  margin-top: -.25rem;
}

.jbm-properties-list.upload-settings .label {
  width: 13rem;
}

/* Attachments */

.table.attachments td .btn-outline-primary {
  width: 3rem;
}


/* Invoicing */

.table.invoices .invoice-state span {
  width: 6.5rem;
}
.table.invoices .invoice-state .badge {
  margin-top: .2rem;
}

.table.invoices td td.blocked {
  text-align: right;
  width: 6rem;
}
.table.invoices td td.info {
  width: 1.75rem;
}

.table.invoices td .total button {
  text-align: right;
}


.table.invoice-items th.delivery-date {
  width: 9.25rem;
}
.table.invoice-items th.section {
  width: 7rem;
}
.table.invoice-items th.amount {
  width: 6rem;
}
.table.invoice-items th.price {
  width: 6rem;
}
.table.invoice-items th.price-type {
  width: 3rem;
}
.table.invoice-items th.totalprice {
  width: 6rem;
}
.table.invoice-items th.actions {
  width: 8rem;
}
.table.invoice-items tr:not(.borderless) td {
  border-top-width: 2px;
}
.table.invoice-items input.delivery-date {
  width: 6rem;
}
.table.invoice-items td.action-buttons .JBMButton {
  padding:0;
  margin-right: 0;
}


.invoice-items .view-section-title {
  cursor: pointer;
}
.invoice-items .view-section-title .form-control {
  background-color: #fff;
}
.invoice-items .view-section-title .amount {
  width: 2rem;
}
.invoice-items .view-section-title .totalprice {
  width: 5rem;
}
.invoice-items .view-section-title .breakdown {
  width: 5rem;
}
.invoice-items .concretes th.comments {
  width: 6rem;
}
.invoice-items .concretes th.worktype {
  width: 12rem;
}

.invoice-items input.description {
  width: 25rem;
}
.invoice-items input.number {
  width: 4rem;
}

/* Invoice forms */

.jbm-modal.customer-invoice {
  width: 33rem;
}
.jbm-modal.customer-invoice #customer-selector .selector-list {
  left: 49%;
  width: 31rem;
  height: 23rem;
}

.jbm-modal.project-invoice {
  width: 65rem;
}
.jbm-modal.project-invoice .jbm-modal-body {
  max-height: 27rem;
}

table.invoice-orders th.check {
  width: 2rem;
}
table.invoice-orders th.worktype {
  width: 15rem;
}
table.invoice-orders th.description {
  width: 18rem;
}
table.invoice-orders th.amount {
  width: 6rem;
}

/* Invoice state flow modal */

.jbm-modal.statesFlow {
  width: 25rem;
  max-height: 22rem;
}
.jbm-modal.statesFlow .badge {
  width: 7rem;
  font-size: 80%;
  font-weight: 500;
}
.jbm-modal.statesFlow .comments {
  padding-top: .25rem;
  font-weight: 400;
}
.jbm-modal.statesFlow hr {
  margin-top: .25rem;
}

/* Invoice */

header.module.invoice .badge {
  margin-top: .4rem;
  height: 1.3rem;
}
header.module.invoice .totals .price {
  font-weight: 600;
}
header.module.invoice .totals .dropdown {
  margin-top: .1rem;
}

.module.invoice .jbm-properties-list.header, .module.quotation .jbm-properties-list.header {
  padding: 0.5rem 0 0.5rem 1.5rem;
}

/* Invoice details */

.invoice-details .expandable button.toggle {
  width: 2rem;
}
.invoice-details .jbm-properties-list .label {
  width: 6.5rem;
}
.invoice-details .jbm-properties-list.collapsed .label {
  width: auto;
}
.invoice-details .jbm-properties-list .jbm-comment-box.customer {
  max-width: 30rem;
}

.invoice-details table.select-project .label {
  width: 4rem;
}
.invoice-details table.select-project .form-group {
  margin-bottom: 0;
}
.invoice-details table.select-project .form-group .form-control {
  margin-left: 0;
}

table.invoice-order th.id {
  width: 7rem;
}
table.invoice-order th.amount,
table.invoice-order th.price,
table.invoice-order th.totalprice,
table.invoice-order th.comments {
  width: 5.5rem;
}
table.invoice-order th.description {
  width: 20rem;
}
table.invoice-order td.action-buttons {
  width: 11.5rem;
  text-align: right;
}
table.invoice-order td.editable {
  vertical-align: middle;
}

.invoice-items .view-section-title table td {
  height: 2rem;
}

table.invoice-articles .form-group.stacked {
  margin-bottom: 0;
}
table.invoice-articles th.pricetype {
  width: 2rem;
}
table.invoice-articles th.type {
  width: 10rem;
}
table.invoice-articles th.display {
  width: 7rem;
}
table.invoice-articles th.price {
  width: 6rem;
}
table.invoice-articles th.invoiced {
  width: 5rem;
}
table.invoice-articles th.amount {
  width: 5.5rem;
}
table.invoice-articles th.totalprice {
  width: 5.5rem;
}
table.invoice-articles label.value {
  padding-top: .36rem;
}
table.invoice-articles th.actions {
  width: 8rem;
}
table.invoice-articles td.actions {
  text-align: right;
  padding-right: 1rem;
}

/* Invoice item */

table.invoice-item .description {
  width: 31rem;
}
table.invoice-item .amount, table.invoice-item .price, table.invoice-item .totalprice {
  width: 7rem;
}

/* Invoice concretes */
.jbm-modal.invoice-concretes {
  width: 65rem;
}
.table.invoice-concretes .production-date {
  width: 5rem;
}
.table.invoice-concretes .recipe {
  width: 15rem;
}
.table.invoice-concretes .worktype {
  width: 12rem;
}
.table.invoice-concretes .ordered, .table.invoice-concretes .delivered {
  width: 4rem;
}
.table.invoice-concretes .comments {
  width: 5rem;
}
.table.invoice-concretes .action {
  width: 9rem;
}

/* Invoice recipe */

.jbm-modal.invoice-recipe {
  width: 56rem;
}
.jbm-modal.invoice-recipe .jbm-modal-body {
  max-height: 25rem;
}
.jbm-modal.invoice-recipe .jbm-properties-list.recipe .label {
  width: 7rem;
}
.jbm-modal.invoice-recipe .jbm-properties-list.concrete .label {
  width: 8rem;
}
.jbm-modal.invoice-recipe .table .group {
  width: 10rem;
}

/* Invoice receipts */

.jbm-modal.invoice-receipts {
  width: 75rem;
}
.jbm-modal.invoice-receipts .jbm-modal-body {
  max-height: 20.5rem;
}
table.invoice-receipts th.id {
  width: 3.5rem;
}
table.invoice-receipts th.production-date {
  width: 7rem;
}
table.invoice-receipts th.amount {
  width: 5rem;
}
table.invoice-receipts th.truck {
  width: 6rem;
}
table.invoice-receipts th.plant {
  width: 7rem;
}
table.invoice-receipts th.driver {
  width: 12rem;
}
table.invoice-receipts th.comment {
  width: 6rem;
}
table.invoice-receipts th.action {
  width: 7rem;
}

/* Invoice partials */

.invoice-partials table th.number {
  width: 4rem;
}
.invoice-partials table th.recipe {
  width: 16rem;
}
.invoice-partials table th.amount {
  width: 6rem;
}
.invoice-partials table th.delivered {
  width: 6rem;
}
.invoice-partials table th.production {
  width: 6rem;
}
.invoice-partials table th.partial {
  width: 4rem;
}

/* Invoice actions */

.jbm-modal.state-action {
  width: 28.5rem;
}

.jbm-modal.invoice-settings {
  width: 36rem;
}
.jbm-modal.invoice-settings .jbm-modal-body {
  height: 21rem;
}

/* Invoice send modal */

.jbm-modal.invoice-send .html-body {
  position: relative;
  height: 12rem;
  overflow-y: auto;
}
.jbm-modal.invoice-send .html-body .mail-viewer {
  padding: 0;
}
.jbm-modal.invoice-send .nav-tabs.message,
.jbm-modal.invoice-send .text-body,
.jbm-modal.invoice-send .html-body {
  margin-left: 8.5rem;
}

/* Concretes modal */

.jbm-modal.concretes .table-xs td {
  padding: 0;
  border-top: none;
  line-height: 1;
}
.jbm-modal.concretes .table-xs.recipe td.worktype {
  width: 15rem;
}
.jbm-modal.concretes .table-xs.recipe td.description {
  width: 18rem;
}
.jbm-modal.concretes .table-xs.recipe td.ordered {
  width: 8rem;
}

.jbm-modal.concretes label.value {
  font-weight: 500;
}

.jbm-modal.concretes .table.articles th {
  line-height: 1.2;
}
.jbm-modal.concretes .table.articles th.display {
  width: 7rem;
}
.jbm-modal.concretes .table.articles th.display {
  width: 7rem;
}
.jbm-modal.concretes .table.articles th.type {
  width: 6rem;
}
.jbm-modal.concretes .table.articles th.recipe-amount {
  width: 6rem;
}
.jbm-modal.concretes .table.articles th.amount {
  width: 9rem;
}
.jbm-modal.concretes .table.articles th.price {
  width: 5rem;
}

/* Invoiceable production orders */

.invoiceable-receipts .toolbar .badge {
  height: 1rem;
  margin-left: .5rem;
  margin-top: .65rem;
}
.invoiceable-receipts .project .table {
  width: auto;
}
.invoiceable-receipts .project .table td {
  vertical-align: top;
  padding: .25rem;
}
.invoiceable-receipts .project .table td.column-1,
.invoiceable-receipts .project .table td.column-3 {
  width: 19rem;
}
.invoiceable-receipts .project .table td.column-2 {
  width: 5.5rem;
}
.invoiceable-receipts .project .table td .btn-link {
  margin-top: -.15rem;
  padding: 0;
}
.invoiceable-receipts .project .table .badge {
  width: auto;
}
.invoiceable-receipts .project .table .btn .badge {
  padding: 0.1rem 0.3rem;
}
.invoiceable-receipts table.receipts th {
  border-top: 1px solid #149acf;
}
.invoiceable-receipts table.receipts th.check {
  width: 2rem;
}
.invoiceable-receipts table.receipts th.id {
  width: 4rem;
}
.invoiceable-receipts table.receipts th.comments {
  width: 4.5rem;
}
.invoiceable-receipts table.receipts th.date {
  width: 8rem;
}
.invoiceable-receipts table.receipts th.price {
  width: 6rem;
}
.invoiceable-receipts table.receipts th.amount {
  width: 4rem;
}
.invoiceable-receipts table.receipts th.recipe {
  width: 20rem;
}
.invoiceable-receipts table.receipts th.worktype {
  width: 12rem;
}
.invoiceable-receipts table.receipts th.actions {
  width: 8.5rem;
}
.invoiceable-receipts table.receipts td input[type=checkbox] {
  margin-top: .4rem;
}


/* Invoice item receipts */

.jbm-modal.receipts .data {
  width: 50rem;
}
.jbm-modal.receipts th.id {
  width: 3rem;
}
.jbm-modal.receipts th.amount {
  width: 4rem;
}
.jbm-modal.receipts th.truck {
  width: 5rem;
}
.jbm-modal.receipts th.driver {
  width: 12rem;
}
.jbm-modal.receipts th.plant {
  width: 12rem;
}
.jbm-modal.receipts th.comment {
  width: 5rem;
}
.jbm-modal.receipts th.action {
  width: 7rem;
}

/* Invoice preview */

.jbm-modal.invoice-preview .jbm-modal-body {
  position: absolute;
  top: 0 !important;
  left: 0;
  right:0;
  bottom: 0;
}

/* Invoicing validation */

.invoice-validation section.number {
  width: 8%;
}
.invoice-validation section.customer {
  width: 25%;
}
.invoice-validation object {
  width: 100%;
  min-height: 30rem;
}

.accordion.validations .card-header .btn-link {
  width: 100%;
  font-weight: 500;
}

table.invoice-validations th.timestamp {
  width: 8rem;
}
table.invoice-validations th.user {
  width: 12rem;
}

form.invoice-validation .comments {
  width: 75%;
}


/* Reports */

section.module.report {
  display: flex;
  flex-direction: column;
}
section.module.report .viewer {
  flex: auto;
}

section.module.report .report-settings .col-form-label {
  text-align: left;
}


/* Users */

table.users td.actions {
  width: 11rem;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #8B9FAD;
}

.login-container form {
  width: 18rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
  border: 1px solid #eee;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.login-container form h5 {
  font-weight: 500;
}

.login-container form hr {
  margin-top: 0;
}

.login-container form .logo-container {
  text-align: center;
}

.login-container form img {
  width: 90px;
  height: 72px;
}

.form-check-inline .form-check-input {
  margin-top: .4rem;
}

.form-group .password-again label {
  padding-top: 0;
}

.jbm-modal.user {
  width: 35rem;
}
.jbm-modal.user .jbm-modal-body {
  height: 19rem;
}

/* Userrights */

table.userrights th.right, table.userrights td.right {
  width: 6rem;
  text-align: center;
}

/* Mail settings */

.selector-list.mail-settings {
  width: 45rem;
}

/* Translations */

.translations #language {
  font-weight: 700;
}

.table.translations th.key {
  width: 45%;
}

.table.translations th.translation {
  width: 55%;
}

.status .progress-container {
  width: 100%;
}

.status .progress {
  border-radius: 0;
}

.status .updates button {
  float: right;
}

/* Helpcentre */

.helpcentre .toolbar {
  padding:0;
}
.helpcentre .toolbar .btn-group {
  padding-top: .32rem;
}

/* Asides */

aside .customer {
  width: 580px;
}

aside form.customer .layout .left, aside form.customer .layout .right {
  width: 100%;
  margin-right: 0;
}

aside .project {
  width: 450px;
}

/* Helpcentre */

aside .aside-body.jbm-help h5 {
    margin-bottom: 0;
}

aside .aside-body.jbm-help hr {
  margin-top: .2rem;
  border-top: 1px solid #15a6df;
}

aside .aside-body.jbm-help .topicLink {
  display: block;
  padding: .5rem 0;
}

/* Recipe-App */

.table.permissions td .check {
  display: inline-block;
  width: 7rem;
  margin-top: 0.5rem;
}
.table.permissions td .check input {
  display: block;
  float: left;
}
.table.permissions td .check label {
  margin-top: -0.25rem;
  display: block;
  padding-left: 0.5rem;
  float: left;
}

/* JBM Components */

.jbm-comment-box {
  background-color: #f5fbfe;
  padding: 0.15rem 0.5rem;
  overflow-y: auto;
  font-style: italic;
}

div.jbm-overlay-panel {
  position: absolute;
  top: 16px;
  right: 0rem;
  width: 225px;
  padding: 1.5rem;
  border-top: 3px solid #15a6df;
  background-color: #fdfdfd;
  color: #333;
  box-shadow: 1px 1px #bbb;
  z-index: 2000;
}

/* Dropdowns */

.jbm-color-palette-picker.dropdown-menu.table-cell {
  width: 16.5rem;
  background-color: #fdfdfd;
}
.jbm-color-palette-picker .backgrounds-container, .jbm-color-palette-picker .foregrounds-container {
  float: left;
}
.jbm-color-palette-picker h6 {
  padding-left: .5rem;
}
.jbm-color-palette-picker .foregrounds-container {
  margin-left: .5rem;
}
.jbm-color-palette-picker .form-check-label {
  width: 4rem;
  margin-top: .4rem;
  border: 1px solid #333;
}
.jbm-color-palette-picker .footer {
  margin-top: 1rem;
}

.jbm-state-dropdown .dropdown {
  display: inline-block;
}

/* JBMModal */
jbm-modal {
  display: none;
  .jbm-modal {
    /* Modal container fixed across whole screen */
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    /* Z-index must be higher than .jbm-modal-backdrop */
    z-index: 1000;
    /* Enables scrolling for tall modals */
    overflow-x: auto;
    .jbm-modal-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 3rem;
      padding: 0.25rem 1rem 0 1rem;
      border-bottom: 1px solid #15a6df;
    }
    .jbm-modal-header .jbm-view-title .title {
      font-size: 1.1rem;
      padding-top: .15rem;
    }
    .jbm-modal-header .jbm-crud-title {
      padding-top: .25rem;
    }
    .jbm-modal-header .jbm-crud-title .title {
      font-size: 1rem;
    }
    .jbm-modal-body {
      margin-top: 3rem;
      margin-bottom: .5rem;
      padding: 1.5rem 1rem 1rem;
      padding-right: 2rem;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .jbm-modal-footer {
      height: 3.75rem;
      padding: .75rem 2rem;
      border-top: 1px solid #15a6df;
    }
  }
  .jbm-modal-backdrop {
    /* Modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* Semi-transparent black  */
    background-color: #8B9FAD;
    opacity: 0.6;
    /* z-index must be below .jbm-modal and above everything else  */
    z-index: 900;
  }
}
body.jbm-modal-open {
  /* body overflow is hidden to hide main scrollbar when modal window is open */
  overflow: hidden;
}

/*
   jbm-modal.app covers 100% app layer
   jbm-modal.content covers 100% content layer
   jbm-modal.module covers 100% module layer
*/
.jbm-modal.app, .jbm-modal.content, .jbm-modal.module {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 11rem;
  right: 0;
  bottom: 0;
  transform: none;
}
.jbm-modal.app {
  left: 0;
}
.jbm-modal.module {
  top: 4rem;
}
.jbm-modal.app .jbm-modal-header, .jbm-modal.content .jbm-modal-header, .jbm-modal.module .jbm-modal-header {
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.jbm-modal.app .jbm-modal-body, .jbm-modal.content .jbm-modal-body, .jbm-modal.module .jbm-modal-body {
  flex: 1;
  margin-top: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
body.sidebar-narrow .jbm-modal.content, body.sidebar-narrow .jbm-modal.module {
  left: 4rem;
}

/* JBM SearchBox */
.jbm-search-box {
  margin-left: 1rem;
  width: 9rem;
}
.jbm-search-box input {
  padding-left: 2rem;
}
.jbm-search-box i.fa-search {
  position: absolute;
  top: 0.7rem;
  left: 0.7rem;
  color: #000;
  z-index: 100;
}
.jbm-search-box.jbm-search-box-xs input {
  padding-top: .2rem;
  padding-bottom: .2rem;
}
.jbm-search-box .btn-primary:disabled {
  background-color: #00628f;
}

.jbm-search-box.jbm-search-box-xs i.fa-search {
  top: 0.55rem;
}
jbm-filter-toggle {
  float: right;
}
.jbm-empty-view {
  padding: .75rem;
  border-top: 1px solid #15a6df;
  background-color: #f8f9fa;
  text-align: center;
  font-weight: 500;
}
.jbm-empty-view.xs {
  padding: 0;
}
.jbm-empty-view.padding {
  padding-left: 1.5rem;
}

.jbm-multi-select {
  position: relative;
}
.jbm-multi-select .container {
  display: flex;
  flex-direction: row;
  min-height: 2.021rem;
}
.jbm-multi-select .selection {
  flex: 1;
}
.jbm-multi-select .selection span {
  display: inline-block;
}
.jbm-multi-select .selection .fa-times {
  color: #999;
}
.jbm-multi-select .options {
  position: absolute;
  max-height: 13.2rem;
  overflow-y: auto;
  border: 1px solid;
  right: 1rem;
  left: 1.5rem;
  background-color: #fff;
  z-index: 4000;
}
.jbm-multi-select .invalid-feedback {
  margin-left: 0.5rem;
}
.jbm-properties-list.stacked label.label, .jbm-properties-list.stacked label.value {
  width:100%;
  margin-bottom: 0.25rem;
}
.jbm-properties-list label.label {
  margin-bottom: 0.5rem;
  padding-right: .35rem;
  vertical-align: top;
}
.jbm-properties-list.stacked label.label {
  margin-bottom: 0;
  padding-right: 0;
}
.jbm-properties-list label.value {
  font-weight: 500;
}
.jbm-properties-list label.value button {
  margin-top: -.3rem;
}
.jbm-properties-list.inline .item {
  display: inline-block;
  margin-right: 1rem;
}
.jbm-properties-list.inline .item label {
  margin-bottom: 0;
}

jbm-spinner .spinner-container {
  float: right;
}

jbm-spinner .spinner-container .fa {
  font-size: 2.15rem;
}

.jbm-table-header {
  padding: .3rem;
}

.jbm-table-layout-container {
  position: relative;
}
.jbm-table-layout {
  top: 10px;
  right: 0;
  width: 225px;
}
.jbm-table-layout .columns-container {
  max-height: 274px;
  overflow-y: auto;
}

.jbm-date-range-input label {
  margin-top: 0.4rem;
}
.jbm-date-range-input .form-control {
  width: 5.7rem;
}
.jbm-date-range-input .form-control.is-invalid, .jbm-date-range-input .form-control.ng-dirty.ng-valid {
  background-image: none;
  padding-right: inherit;
}
.jbm-date-range-input .input input {
  margin-left: .5rem;
  font-size: .8rem;
}
.jbm-date-range-input .input input.is-invalid {
  color: maroon;
}
.jbm-date-range-input .button button {
  margin-top: -0.1rem;
  padding: .25rem;
}
.jbm-date-range-input button:focus {
  box-shadow: none;
}

/* Activity Log */
.jbm-activity-log {
  width: 600px;
}
.modal-body.activity-log {
  min-height: 430px;
}

/* Dark mode */
body.dark-mode {
  filter: invert(1);
}
body.dark-mode .content {
  background-color: #e0e0e0;
  color: #000 !important;
}
body.dark-mode select option {
  background-color: #000 !important;
  color: #fff !important;
}
body.dark-mode .jbm-modal {
  background-color: #e0e0e0;
}
body.dark-mode .table td {
  border-top: 1px solid #b3b3be;
}
body.dark-mode .form-control, body.dark-mode .form-select {
  border-color: #b3b3be;
}
body.dark-mode .badge.bg-light {
  background-color: #ddd !important;
}


/* Settings */

.module.settings h5 .fa {
  width: 1rem;
}
.module.settings .card {
  margin-bottom: .25rem;
}
.module.settings .card a, .module.reports .card a {
  display: block;
  padding: .5rem 1.25rem;
}
.module.settings .card h6 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  line-height: 2.15;
  font-size: .9rem;
  font-weight: 400;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8d97a2;
  border-radius: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #149acf;
}

/* Responsive Design */

@media (min-width: 576px) {
  .form-inline label {
    justify-content: flex-end;
  }

  .modal-dialog {
    max-width: 600px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 750px;
  }
}

/* Helper classes */

.bold {
  font-weight: 400;
}

.align-left {
  text-align: left;
}

.align-right {
    text-align: right;
  }
.align-center {
  text-align: center;
}

/* Selector components */
.selector-container {
  width:100% !important;
}
.selector-container .col-form-label {
  float: left;
}
.form-group.row.stacked .selector-container label {
  display: block;
  margin-bottom: 0;
}
.form-group .selector-container .invalid-feedback .error {
  margin-left: .5rem;
}
.form-group.row.stacked .selector-container .invalid-feedback .error {
  margin-left: 0;
}
.selector-container .box {
  display: flex;
  flex-direction: row;
  float: left;
  padding: 0;
}
.selector-container .box.form-control {
  padding-top: 0.06rem;
  padding-bottom: 0.06rem;
}
.selector-container .box .icon {
  position: absolute;
  top: calc(50% - 10px);
  right: 1rem;
}
.selector-container .box .selected {
  flex: auto;
  padding: 0.25rem 0.75rem;
  min-height: 1.77rem;
}
.selector-container .box .selected label {
  margin-bottom: 0;
}
.selector-container .box .selected .table tr:first-child td {
  padding-top: 0;
}
.selector-container .box .selected .table tr:last-child td {
  padding-bottom: 0;
}
.selector-container .box .selected .table tr td:first-child {
  padding-left: 0;
}
.selector-container .box .buttons {
  float: right;
}
.selector-container .box .buttons .btn {
  color: #999;
}
.selector-container .selector-list {
  position: fixed;
  left: 50%;
  top: 50%;
  height: 25rem;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border: 2px solid #ced4da;
  background-color: #fff;
  z-index: 1002;
  overflow-y: auto;
}
.selector-container .selector-list .title {
  padding-bottom: .5rem;
}
.selector-container .selector-list .title h5 {
  float: left;
  line-height: 2;
}
.selector-container .selector-list .toolbar {
  display: block;
}
.selector-container .selector-list .jbm-search-box {
  width: 10rem;
  margin-left:0;
  margin-right: 1rem;
}
/* Correct alignment if selector is embedded in editable table cell */
table tr.edit-active td .selector-container .selector-list table td {
  vertical-align: middle;
}
.selector-container .invalid-feedback {
  clear: both;
  padding-top: .25rem;
}
.selector-container .invalid-feedback .col-form-label {
}
.selector-container .invalid-feedback .error {
  float: left;
  margin-left: 1rem;
}
.selector-container .form-control.is-invalid {
  background-position: right 1.5rem center;
}
.selector-container .form-control.is-valid {
  background-position: right 3.5rem center;
}

/* Bootstrap 4 Utilities */

/*-- float utilities --*/
.float-left {
  float: left   !important;
}
.float-right {
  float: right  !important;
}
.float-none {
  float: none   !important;
}
@media (min-width: 768px) {
  .float-sm-left {
    float: left   !important;
  }
  .float-sm-right {
    float: right  !important;
  }
  .float-sm-none {
    float: none   !important;
  }
}
@media (min-width: 992px) {
  .float-md-left {
    float: left   !important;
  }
  .float-md-right {
    float: right  !important;
  }
  .float-md-none {
    float: none   !important;
  }
}
@media (min-width: 1200px) {
  .float-lg-left {
    float: left   !important;
  }
  .float-lg-right {
    float: right  !important;
  }
  .float-lg-none {
    float: none   !important;
  }
}
/*-- text utilities --*/
.text-left {
  text-align: left   !important;
}
.text-right {
  text-align: right  !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 768px) {
  .text-sm-left {
    text-align: left   !important;
  }
  .text-sm-right {
    text-align: right  !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-md-left {
    text-align: left   !important;
  }
  .text-md-right {
    text-align: right  !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left   !important;
  }
  .text-lg-right {
    text-align: right  !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
/*-- spacing utilities --*/
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}
.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pr-1 {
  padding-right: 0.25rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3 {
  padding-top: 1rem !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.pb-3 {
  padding-bottom: 1rem !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.pr-4 {
  padding-right: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4 {
  padding-left: 1.5rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.pr-5 {
  padding-right: 3rem !important;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.pl-5 {
  padding-left: 3rem !important;
}
.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5 {
    margin-left: 3rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5 {
    padding-left: 3rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3 {
    margin-left: 1rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5 {
    margin-left: 3rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3 {
    padding-left: 1rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5 {
    padding-left: 3rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3 {
    margin-left: 1rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4 {
    margin-left: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5 {
    margin-left: 3rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3 {
    padding-left: 1rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5 {
    padding-left: 3rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
}
